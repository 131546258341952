<template>
  <div class="my-qrcode">
    <navbar bg="transparent" />
    <div class="wrapper">
      <div class="qrcode">
        <img :src="userInfo.qrcode" alt="" />
      </div>
      <button class="copy-btn" @click="copy(userInfo.inviteUrl)">
        立即复制
      </button>
    </div>
    <!-- <div class="invite-link">{{ userInfo.inviteUrl }}</div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/h5/Navbar";

export default {
  name: "UserCenter",
  components: {
    Navbar
  },
  computed: {
    ...mapState(["userInfo", "vipIndex"]),
    userVip() {
      return (
        this.vipIndex.find(item => item.id === this.userInfo.vip_level) || {}
      );
    }
  },
  created() {
    // this.fetchVipIndex();
  },
  methods: {
    // ...mapActions(["fetchVipIndex"]),

    copy(text) {
      // 数字没有 .length 不能执行selectText 需要转化成字符串
      const textString = text.toString();
      let input = document.querySelector("#copy-input");
      if (!input) {
        input = document.createElement("input");
        input.id = "copy-input";
        input.readOnly = "readOnly"; // 防止ios聚焦触发键盘事件
        input.style.position = "fixed";
        input.style.left = "-1000px";
        input.style.zIndex = "-1000";
        document.body.prepend(input);
      }
      // 这里设置一个top值为屏幕中心值 ios上不设置会滚动到顶部或底部
      input.style.top = document.documentElement.clientHeight / 2 - 9 + "px";

      input.value = textString;
      // ios必须先选中文字且不支持 input.select();
      selectText(input, 0, textString.length);
      console.log(document.execCommand("copy"), "execCommand");
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$toast("复制成功");
        // alert('已复制到粘贴板');
        return true;
      }
      input.blur();

      // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
      // 选择文本。createTextRange(setSelectionRange)是input方法
      function selectText(textbox, startIndex, stopIndex) {
        if (textbox.createTextRange) {
          //ie
          const range = textbox.createTextRange();
          range.collapse(true);
          range.moveStart("character", startIndex); //起始光标
          range.moveEnd("character", stopIndex - startIndex); //结束光标
          range.select(); //不兼容苹果
        } else {
          //firefox/chrome
          textbox.setSelectionRange(startIndex, stopIndex);
          textbox.focus();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.my-qrcode {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  // align-items: center;

  .wrapper {
    height: 2001px;
    width: 1125px;
    background-image: url(../../../assets/h5images/user/qrbg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  .qrcode {
    width: 600px;
    height: 600px;
    position: absolute;
    top: 510px;
    left: 50%;
    transform: translateX(-50%);
    // margin: 370px auto 150px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .invite-link {
    margin-top: 200px;
    font-size: 40px;
    text-align: center;
  }

  .copy-btn {
    position: absolute;
    top: 1300px;
    left: 50%;
    transform: translateX(-50%);
    // margin: 50px auto;
    flex: none;
    width: 305px;
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 50px;
    background-color: #8c4134;
    color: #fff;
    // color: #8c4134;
  }
}
</style>
